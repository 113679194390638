export const pageNameByDefworkflow = (defWorkflowId, fromPage) => {
  switch (defWorkflowId) {
    case 2: // REGISTRATION TRANSPORTER
      return `${fromPage}-company-registration`;
    case 15: // DOCUMENT SUPPORT
      return `${fromPage}-document-support`;
    case 5: // MOU BY SPV MARKETING
    case 6: // MOU BY MARKETING MANAGER
    case 8: // ADDEDUM MOU
    case 9: // ADDEDUM MOU BY MARKETING MANAGER
      return `${fromPage}-mou`;
    default:
      break;
  }
};

export const servicesByDefworkflow = (defWorkflowId) => {
  switch (defWorkflowId) {
    case 2:
      return {
        service: 'approval',
        function: 'getWorkflow',
      };
    case 15:
      return {
        service: 'approval',
        function: 'getDocumentSupport',
      };
    case 5:
    case 6:
    case 8:
    case 9:
      return {
        service: 'mou',
        function: 'getDetailWorkflowMou',
      };
    default:
      break;
  }
};
